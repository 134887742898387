export const isWebBrowser = typeof window !== 'undefined' && window.document;
export const GOOGLE_MAP_SCRIPT_BASE_URL = 'https://maps.googleapis.com/maps/api/js';
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_STATIC_API_KEY = process.env.NEXT_PUBLIC_STATIC_GOOGLE_MAPS_API_KEY;
export const GOOGLE_PRIVACY_POLICIES = process.env.NEXT_PUBLIC_GOOGLE_PRIVACY_POLICIES;

const libraries = 'places';
const languageQueryParam = '&language=de';

export const loadGoogleMapScript = () => {
  const googleMapsScriptUrl = `${GOOGLE_MAP_SCRIPT_BASE_URL}?key=${GOOGLE_MAPS_API_KEY}&libraries=${libraries}${languageQueryParam}&callback=initMaps`;
  if (!isWebBrowser) return Promise.resolve();

  if (typeof google !== 'undefined') {
    // eslint-disable-next-line no-undef
    if (google.maps) return Promise.resolve();
  }
  const mapsInitialized = new Promise(resolve => {
    (window as any).initMaps = () => {
      // console.log('mapsIsInit');
      resolve(true);
    };
  });
  const scriptElements = document.querySelectorAll(`script[src*="${GOOGLE_MAP_SCRIPT_BASE_URL}"]`);

  if (scriptElements && scriptElements.length) {
    return new Promise(resolve => {
      // in case we already have a script on the page and it's loaded we resolve
      if (typeof google !== 'undefined') return resolve();

      // otherwise we wait until it's loaded and resolve
      scriptElements[0].addEventListener('load', () => resolve());
    }) as Promise<void>;
  }

  const el = document.createElement('script');
  el.src = googleMapsScriptUrl;

  document.body.appendChild(el);
  return mapsInitialized;
};
