import clsx from 'clsx';

import {Popover} from '@mui/material';

import {useStarcarDateRangePickerStateContext} from '../../../hooks/BPR/useStarcarDateRangePickerState';
import Grid from '../Grid';
import {RangeCalendar} from './RangeCalendar';
import {TimeSelectFlyout} from './TimeSelectFlyout';
import styles from './styles.module.scss';

export const DefaultPopover = () => {
  const {
    isDesktop,
    isWidget,
    isCalendarOpen,
    ref,
    bprRef,
    setCalendarOpen,
    selectedTimeField,
    setSelectedTimeField,
  } = useStarcarDateRangePickerStateContext();
  return (
    <div id="Test" style={{display: !isDesktop || isWidget ? 'none' : 'block'}}>
      <Grid
        xxs={12}
        className={clsx({
          [styles.open]: isCalendarOpen,
          [styles.calendarWrapper]: true,
        })}
      >
        <Popover
          open={!!(isCalendarOpen && isDesktop && !isWidget)}
          anchorEl={bprRef?.current || ref?.current}
          // disablePortal
          disableScrollLock={false}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          className={'mui-fixed'}
          sx={{
            '.MuiPopover-paper': {
              padding: {lg: '0 35px', xxl: '0 40px'},
              width: '94%',
              maxWidth: 'calc(1410px * 0.94)',
              // left: '50% !important',
              // transform: 'translate(calc(-50%), 0) !important',
            },
          }}
          onClose={() => setCalendarOpen(false)}
        >
          <RangeCalendar />
        </Popover>
      </Grid>
      <Grid
        xxs={12}
        className={clsx({
          [styles.open]: !!selectedTimeField,
          [styles.calendarWrapper]: true,
        })}
      >
        <Popover
          open={selectedTimeField && isDesktop && !isWidget}
          anchorEl={bprRef?.current || ref?.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          disableScrollLock={false}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            '.MuiPopover-paper': {
              width: '94%',
              maxWidth: 'calc(1410px * 0.94)',
              // left: '50% !important',
              // transform: 'translate(calc(-50%), 0) !important',
            },
          }}
          onClose={() => {
            setSelectedTimeField(null), setCalendarOpen(false);
          }}
        >
          <TimeSelectFlyout />
        </Popover>
      </Grid>
    </div>
  );
};
