import useOutsideAlerter from 'hooks/useOutsideAlerter';
import usePrevious from 'hooks/usePrevious';
import {useRouter} from 'next/router';
import {
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';import {DateValue, useDateRangePicker} from 'react-aria';
import {useDateRangePickerState} from 'react-stately';

import {
  CalendarDateTime,
  isSameDay,
  isWeekend,
  parseDateTime,
  today
} from '@internationalized/date';
import {useMediaQuery} from '@mui/material';

import {StationDatepickerData} from 'lib/api/backend.schemas';

import {breakpoint} from 'styles/mui/scss';

import {
  // checkSameDate,
  formatCalenderDateTimeToTimeString,
  getAvailableTimeSlots,
  getExcludedDates,
  setToNext15MinSlot, // setToNext15MinSlot,
} from 'components/section-components/bpr/getDatepickerFilterFunctions';

import {
  IDateRangePickerProps,
  TimeSlotCategory,
} from '../../components/basic-components/Datepicker/DateRangePicker';
import {generateArrivalTimeOptions} from '../../components/basic-components/Datepicker/RangeCalendar';
import { UseFormReturn } from 'react-hook-form';

const formatDateValueToDateString = date => {
  return date ? `${date.year}-${date.month?.toString().padStart(2, '0')}-${date.day
    ?.toString()
    .padStart(2, '0')}` : '';
};
export const useStarcarDateRangePickerState = ({
  props,
  formMethods,
  stationsById,
  bprRef,
}: {
  props: IDateRangePickerProps;
  formMethods: UseFormReturn<any, any>;
  stationsById;
  bprRef?: MutableRefObject<HTMLFormElement>;
}) => {
  const {locale} = useRouter();
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [excludedDates, setExcludedDates] = useState<string[]>([]);
  const [isCalendarOpen, setCalendarOpen] = useState(props?.defaultOpen || false);
  const [selectedTimeField, setSelectedTimeField] = useState(null);
  const state = useDateRangePickerState({
    ...props,
    shouldCloseOnSelect: false,
  });
  const resetDatepicker = () => {
    setCalendarOpen(false);
    setSelectedTimeField(null);
    setIsSelecting(false)
    state.setValue({end: null, start: null});
  };

  const resetDateTimepickerValues = () => {
    state.setValue({end: null, start: null})
    setIsSelecting(false)
    setValue('arrival', null);
    setValue('departure',null);
    setValue('arrivalTime', null);
    setValue('departureTime',null);
    setMinValue(initialMinValue)

  }

  const clickOutsideHandler = useCallback(() => {
    if (isCalendarOpen || !!selectedTimeField) {
      setCalendarOpen(false);
      setSelectedTimeField(null);
      setIsSelecting(false)
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [isCalendarOpen, selectedTimeField]);
  useOutsideAlerter(props?.widgetRef, clickOutsideHandler, ['.close-bpr-modal-button', '.no-outsideclick']);

  const ref = useRef();
  const {
    startFieldProps,
    endFieldProps,

    // Assuming calendarProps is what RangeCalendar needs
    calendarProps,
  } = useDateRangePicker(
    {
      ...props,
      startName: 'departure',
      endName: 'arrival',
      label: 'dateRangepicker',
    },
    state,
    ref,
  );
  const presetStationDatepickerData =  props?.presetStation?.datepickerData ? {
    ...props.presetStation.datepickerData,
    partner: props?.presetStation?.partner || undefined
  } : null;
  const [datepickerData, setDatepickerData] = useState(presetStationDatepickerData ||  null as StationDatepickerData);
  const isDesktop = useMediaQuery(`(min-width:${breakpoint.lg}px)`);

  const [availableTimeSlots, setAvailableTimeSlots] = useState<TimeSlotCategory[]>([
    {name: 'Night', timeSlots: []},
    {name: 'Morning', timeSlots: []},
    {name: 'Midday', timeSlots: []},
    {name: 'Evening', timeSlots: []},
  ]);

  const closeModal = useCallback(() => {
    setCalendarOpen(false);
    setIsSelecting(false)
    setSelectedTimeField(null);
  }, [setCalendarOpen, setSelectedTimeField]);
  const openCalendar = e => {
    if (e.target.getAttribute('data-id') !== 'time-adornment') {
      setCalendarOpen(true);
      setSelectedTimeField(null);
    }
  };

  const {setValue, watch, resetField, formState} = formMethods;
  const timeValue = watch(selectedTimeField);
  const formValues = watch();
  const lastDeparture = usePrevious(formValues?.departure);
  
  useEffect(() => {
    if (formValues.departure && lastDeparture !== formValues?.departure && datepickerData) {
      const formattedDate = formatDateValueToDateString(formValues?.departure);
      const availableSlots = getAvailableTimeSlots(
        formattedDate,
        datepickerData.allowedTimes,
        datepickerData.holidays,
        datepickerData.nextPossibleDeparture,
        props?.minValue as CalendarDateTime,
        props?.maxValue as CalendarDateTime,
      );
      setAvailableTimeSlots(availableSlots);
    }
  }, [formValues.departure, datepickerData, props?.minValue]);

  const lastStation = usePrevious(formValues.station);
  const resetFormValues = () => {
    if (formValues.station != lastStation) {
      setValue('arrival', null);
      setValue('departure', null);
      setValue('arrivalTime', null);
      setValue('departureTime', null);
    }
  };

  const [initialMinValue, setInitialMinValue] = useState(
    props?.minValue ?
      props.minValue < today('Europe/Berlin') ?
        today('Europe/Berlin')
        : props.minValue
      : today('Europe/Berlin')
  );

  useEffect(() => {
    if (datepickerData?.nextPossibleDeparture && parseDateTime(datepickerData?.nextPossibleDeparture) != initialMinValue) {
      setInitialMinValue(parseDateTime(datepickerData?.nextPossibleDeparture))
    }
  }, [datepickerData?.nextPossibleDeparture]);

  const initialMaxValue = props?.maxValue || undefined;
  const [minValue, setMinValue] = useState<DateValue>(initialMinValue);
  const [maxValue, setMaxValue] = useState<DateValue>(initialMaxValue);
  const getFirstTimeslotOfDay = useCallback((date: DateValue) => {
    const formattedDate = formatDateValueToDateString(date);
    const availableSlots = getAvailableTimeSlots(
      formattedDate,
      datepickerData.allowedTimes,
      datepickerData.holidays,
      datepickerData.nextPossibleDeparture,
      props?.minValue as CalendarDateTime,
      props?.maxValue as CalendarDateTime,
    );
    const reducedSlots = availableSlots.reduce((acc, {timeSlots}) => [...acc, ...timeSlots], []);
    return reducedSlots?.[0];
  }, [datepickerData?.allowedTimes, datepickerData?.holidays, datepickerData?.nextPossibleDeparture, props?.maxValue, props?.minValue]);
  const getLastTimeslotOfDay = useCallback((date: DateValue) => {
    const formattedDate = formatDateValueToDateString(date);
    const availableSlots = getAvailableTimeSlots(
      formattedDate,
      datepickerData.allowedTimes,
      datepickerData.holidays,
      datepickerData.nextPossibleDeparture,
      props?.minValue as CalendarDateTime,
      props?.maxValue as CalendarDateTime,
    );
    const reducedSlots = availableSlots.reduce((acc, {timeSlots}) => [...acc, ...timeSlots], []);
    return reducedSlots?.[reducedSlots?.length - 1];
  }, [datepickerData?.allowedTimes, datepickerData?.holidays, datepickerData?.nextPossibleDeparture, props?.maxValue, props?.minValue]);
  useEffect(() => {
    if (!props.minValue && formValues.station && datepickerData) {
      setMinValue(parseDateTime(datepickerData?.nextPossibleDeparture));
    }
  }, [datepickerData, formValues.station]);
  useEffect(() => {
    // set Maximum value to departure DateTime + max renatal duration if set.
    if (formValues?.departureTime) {
      const departureDateTime = parseDateTime(
        `${formatDateValueToDateString(formValues?.departure)}T${formValues?.departureTime}`,
      ).add({hours: props?.maxRentalDuration * 24 || 0});
      if (props?.maxRentalDuration && departureDateTime.compare(initialMaxValue) <= 0) {
        setMaxValue(departureDateTime);
      }
    }
  }, [formValues?.departureTime]);

  useEffect(() => {
    const {datepickerData: data = {}, partner} = stationsById?.[formValues.station] || {};
    if (
      formValues.station &&
      stationsById?.[formValues.station] &&
      JSON.stringify({...data, partner}) !== JSON.stringify(datepickerData)
    ) {
      setDatepickerData({...data, partner});
    }
  }, [formValues?.station, formValues?.stationTextField, stationsById, datepickerData]);
  const [hasSelectedDepartureTime, setHasSelectedDepartureTime ] = useState(false);

  const handleTime = (selectedField, time) => {
    setValue(selectedField, time);
    if (selectedField === 'departureTime' && !hasSelectedDepartureTime) {
      setHasSelectedDepartureTime(true);
      setSelectedTimeField('arrivalTime');
    } else {
      setSelectedTimeField(null);
      setTimeout(() => {
        formMethods.setFocus('distance');
      }, 100);
    }
  };
  useEffect(() => {
    if (formValues.station && datepickerData) {
      const excludedDates = getExcludedDates(
        datepickerData.disabledDates,
        datepickerData.disabledWeekdays,
      );
      setExcludedDates(excludedDates);
      // remove maxValue Date for selection in departure if there there is no timeslot before the maxValue
      if (props.maxRentalDuration) {
        if (!getFirstTimeslotOfDay(initialMaxValue)) {
          setMaxValue(initialMaxValue.subtract({days: 1}));
        }
      }
    }
  }, [formValues.station, datepickerData]);

  useEffect(() => {
    if(!!selectedTimeField) {
      setIsSelecting(false);
      setMinValue(initialMinValue);
    }
  },[selectedTimeField])
  useEffect(() => {
    if(!isCalendarOpen) {
      setIsSelecting(false);
      setMinValue(initialMinValue);
    }
  }, [isCalendarOpen])
  useEffect(() => {
    if (
      datepickerData &&
      formValues?.departure &&
      formState.isDirty &&
      (props?.isWidget || lastDeparture !== formValues?.departure)
    ) {
      let departureTime = setToNext15MinSlot(new Date(datepickerData?.nextPossibleDeparture));
      let arrivalTime = setToNext15MinSlot(new Date(datepickerData?.nextPossibleDeparture));

      if (isSameDay(parseDateTime(datepickerData?.nextPossibleDeparture), formValues?.departure)) {
        if (props?.minValue) {
          departureTime = setToNext15MinSlot(new Date(props.minValue.toString()));
          arrivalTime = setToNext15MinSlot(new Date(props.minValue.toString()), 1);
        } else {
          departureTime = setToNext15MinSlot(new Date(datepickerData?.nextPossibleDeparture));
          arrivalTime = setToNext15MinSlot(new Date(datepickerData?.nextPossibleDeparture));
        }
      } else {
        const firstTimeSlotOfDay = getFirstTimeslotOfDay(formValues?.departure);
        if (firstTimeSlotOfDay) {
          departureTime = firstTimeSlotOfDay;
        }
        if (initialMaxValue && isSameDay(initialMaxValue, formValues?.departure)) {
          // if departure date is same date as maxValue date , set departureTime to first timeslot of the day

          const firstTimeSlotOfDay = getFirstTimeslotOfDay(initialMaxValue);
          if (firstTimeSlotOfDay) {
            departureTime = firstTimeSlotOfDay;
          }
        }
        // default arrivalTime is same time as departure
        arrivalTime = departureTime;
      }

      // if departure is on weekend (saturday/sunday) and arrival following day, default arrivalTime is at 08:00
      if (formValues?.arrival && formValues?.departure && isWeekend(formValues?.departure, 'de-DE')) {
        if (isSameDay(formValues.departure.add({days: 1}), formValues.arrival) && departureTime <= '12:00') {
          arrivalTime = '08:00';
        }
      }

      // if departure is same day as arrival, default arrivalTime is +2h
      if (formValues?.arrival && formValues?.departure && isSameDay(formValues?.departure, formValues?.arrival)) {
        let defaultArrival = parseDateTime(
          `${formatDateValueToDateString(formValues?.departure)}T${departureTime}`,
        ).add({hours: 2});
        arrivalTime = formatCalenderDateTimeToTimeString(defaultArrival);
      }

      if (
        maxValue &&
        parseDateTime(`${formatDateValueToDateString(formValues?.arrival)}T${arrivalTime}`).compare(
          maxValue,
        ) > 0
      ) {
        // there was a bug on KLM where maxValue didnt have a timeValue , this should fix it
        arrivalTime = formatCalenderDateTimeToTimeString(parseDateTime(`${formatDateValueToDateString(maxValue)}T${arrivalTime}`)) // formatCalenderDateTimeToTimeString(maxValue as any);
      }
      // If the selected departureTime is before the first Timeslot of the day or after the last timeslot of the day , set it so its inside the available timeframe
      if (!formValues?.departureTime || parseDateTime(
        `${formatDateValueToDateString(formValues?.departure)}T${formValues?.departureTime}`,
      ).compare(parseDateTime(`${formatDateValueToDateString(formValues?.departure)}T${getFirstTimeslotOfDay(formValues?.departure) || '09:00'}`)) <= 0 || parseDateTime(
        `${formatDateValueToDateString(formValues?.departure)}T${formValues?.departureTime}`,
      ).compare(parseDateTime(`${formatDateValueToDateString(formValues?.departure)}T${getLastTimeslotOfDay(formValues?.departure)}`)) >= 0) {
        setValue('departureTime', departureTime);
      }
      if (props?.maxRentalDuration) {
        if (
          parseDateTime(
            `${formatDateValueToDateString(formValues?.arrival)}T${arrivalTime}`,
          ).compare(
            parseDateTime(
              `${formatDateValueToDateString(formValues?.departure)}T${departureTime}`,
            ).add({hours: props.maxRentalDuration * 24}),
          ) > 0
        ) {
          arrivalTime = departureTime;
          setMaxValue(
            parseDateTime(
              `${formatDateValueToDateString(formValues?.departure)}T${departureTime}`,
            ).add({hours: props.maxRentalDuration * 24}),
          );
        }
      }
      setValue('arrivalTime', arrivalTime);
    }
  }, [datepickerData, formValues?.departure, formValues?.arrival, formValues.station, lastDeparture, setValue]);

  // after selecting departureTime, reset default arrivalTime in +2h when same day and selected departureTime is after arrivalTime
  useEffect(() => {
    if (hasSelectedDepartureTime
      && formValues?.departureTime && formValues?.arrivalTime && formValues?.arrival && formValues?.departure
      && isSameDay(formValues?.departure, formValues?.arrival)
      && formValues?.departureTime >= formValues?.arrivalTime
    ) {
      let newDefaultArrival = parseDateTime(
        `${formatDateValueToDateString(formValues?.departure)}T${formValues?.departureTime}`,
      ).add({hours: 2});
      if (isSameDay(formValues?.departure, newDefaultArrival)) {
        setValue('arrivalTime', formatCalenderDateTimeToTimeString(newDefaultArrival));
      }
    }
  }, [formValues?.departureTime, formValues?.arrivalTime, formValues?.departure, formValues?.arrival, hasSelectedDepartureTime]);

  // after selecting departure and updating availableTimeSlots, reset departureTime to first available time slot when previous selected value isn't available
  useEffect(() => {
    
    if (formValues?.departure && formValues?.departureTime && availableTimeSlots && datepickerData) {
      const formattedDate = formatDateValueToDateString(formValues?.departure);
      const availableSlots = getAvailableTimeSlots(
        formattedDate,
        datepickerData.allowedTimes,
        datepickerData.holidays,
        datepickerData.nextPossibleDeparture,
        props?.minValue as CalendarDateTime,
        props?.maxValue as CalendarDateTime,
      );
      let reducedSlots = availableSlots.reduce((acc, {timeSlots}) => [...acc, ...timeSlots], [] as string[]);
      if (!reducedSlots.includes(formValues?.departureTime)) {
        setValue('departureTime', getFirstTimeslotOfDay(formValues?.departure));
      }
    }
  }, [formValues?.departure, formValues?.departureTime, availableTimeSlots]);

  useEffect(() => {
    if (props?.maxRentalDuration && formValues?.departure && formValues?.departureTime) {
      if (
        parseDateTime(
          `${formatDateValueToDateString(formValues?.departure)}T${formValues?.departureTime}`,
        )
          .add({hours: props.maxRentalDuration * 24})
          .compare(initialMaxValue) > 0
      ) {
        setMaxValue(initialMaxValue);
      } else {
        setMaxValue(
          parseDateTime(
            `${formatDateValueToDateString(formValues?.departure)}T${formValues?.departureTime}`,
          ).add({hours: props.maxRentalDuration * 24}),
        );
      }
    }
  }, [formValues?.departureTime]);

  const timeOptions = generateArrivalTimeOptions(formValues, maxValue);
  useEffect(() => {
    if(isCalendarOpen) {
      formMethods.clearErrors(['departure', 'arrival', 'arrivalTime', 'departureTime'])
    }
  }, [isCalendarOpen])
  useEffect(() => {
      formMethods.clearErrors(['departure', 'arrival', 'arrivalTime', 'departureTime'])
  }, [formValues?.departureTime, formValues?.arrivalTime])
  const cellClickHandler = useCallback(
    (date: DateValue) => {
      if (!isSelecting && minValue && initialMaxValue && isSameDay(minValue, initialMaxValue)) {
        setValue('departure', minValue);
        setValue('arrival', initialMaxValue);
        setCalendarOpen(false);
        setSelectedTimeField('departureTime');
      } else if (!isSelecting) {
        setIsSelecting(true);
        setMinValue(date);
        if (
          props?.maxRentalDuration &&
          date.add({days: props?.maxRentalDuration}) < initialMaxValue
        ) {
          setMaxValue(date.add({days: 1}));
        }
      } else {
        if (!props?.maxRentalDuration) {
          setIsSelecting(false);
        }
        setMaxValue(initialMaxValue);
      }
    },
    [isSelecting, props?.maxRentalDuration, initialMaxValue],
  );
  return {
    locale,
    startFieldProps,
    setSelectedTimeField,
    endFieldProps,
    calendarProps,
    selectedTimeField,
    state,
    isSelecting,
    setIsSelecting,
    availableTimeSlots,
    excludedDates,
    isDesktop,
    ref,
    bprRef,
    isCalendarOpen,
    setCalendarOpen,
    clickOutsideHandler,
    minValue,
    setMinValue,
    maxValue,
    setMaxValue,
    openCalendar,
    closeModal,
    setValue,
    resetField,
    formValues,
    isHeaderBPR: props?.isHeaderBPR,
    handleTime,
    timeValue,
    timeOptions,
    isWidget: props.isWidget,
    maxRentalDuration: props?.maxRentalDuration,
    cellClickHandler,
    resetDatepicker,
    resetDateTimepickerValues,
    datepickerData,
    resetFormValues,
    initialMaxValue,
    formMethods,
    initialMinValue,
    hasSelectedDepartureTime,
    setHasSelectedDepartureTime,
  };
};
type IStarcarDateRangePickerState = ReturnType<typeof useStarcarDateRangePickerState>;
export const StarcarDateRangePickerStateContext = createContext<IStarcarDateRangePickerState>(
  {} as IStarcarDateRangePickerState,
);
export const useStarcarDateRangePickerStateContext = () => {
  const starcarDateRangePickerState = useContext(StarcarDateRangePickerStateContext);
  return starcarDateRangePickerState;
};
