import {DateValue} from 'react-aria';
import {I18nProvider} from 'react-aria';
import {DateRangePickerStateOptions} from 'react-stately';

import {useStarcarDateRangePickerStateContext} from '../../../hooks/BPR/useStarcarDateRangePickerState';
import Box from '../Box';
import Grid from '../Grid';
import ScrollBox from '../ScrollBox';
import {DefaultPopover} from './DefaultPopover';
import {RangeCalendar} from './RangeCalendar';
import {TimeSlotCategories} from './TimeSlotCategories';
import {FormInputDateArrival, FormInputDateDeparture, FormInputTimeDepartureMobile, FormInputTimeArrivalMobile} from './formInputs';
import styles from './styles.module.scss';
import Modal from '../Modal';
import { Paper } from '@mui/material';
import clsx from 'clsx';
import { Station } from 'lib/api/backend.schemas';
import { withErrorBoundary } from 'components/error/ErrorBoundary';

// Importing RangeCalendar
export interface IDateRangePickerProps extends DateRangePickerStateOptions<DateValue> {
  isWidget: boolean;
  columnSpacing?: string;
  departureDisabled?: boolean;
  arrivalDisabled?: boolean;
  widgetRef?: any;
  presetStation?: Station;
  maxRentalDuration?: number;
  isHeaderBPR?: boolean;
}

export interface TimeSlotCategory {
  name: string;
  timeSlots: string[];
}
function DateRangePickerComp(props: IDateRangePickerProps) {
  const starcarDateRangePickerState = useStarcarDateRangePickerStateContext();
  const {
    locale,
    selectedTimeField,
    state,
    isDesktop,
    ref,
    formValues: {
      arrival,
      departure,
    },
    isCalendarOpen,
    isWidget,
  } = starcarDateRangePickerState;

  return (
    <I18nProvider locale={locale}>
      {/* <label {...labelProps}>{props.label}</label> */}
      {/* <div {...groupProps} ref={ref} style={{display: 'inline-flex'}}> */}
      <Grid
        // {...groupProps}
        ref={ref}
        container
        rowSpacing={{xxs: isWidget ? '20px' : '10px'}}
        columnSpacing={
          props?.columnSpacing ? props?.columnSpacing : {xxs: '10px', sm: '10px', lg: '5px', xxl: '20px'}
        }
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <Grid xxs={6} sm={6} order={1}>
          <FormInputDateDeparture departureDisabled={props?.departureDisabled} />
        </Grid>
        <Grid xxs={6} sm={6} order={3}>
          <FormInputDateArrival arrivalDisabled={props?.arrivalDisabled} />
        </Grid>
        <Grid
          xxs={12}
          order={isCalendarOpen && !isDesktop ? 5 : 5}
          sx={{
            display: isWidget || !isDesktop ? 'block' : 'none',
            padding: !isCalendarOpen && 0,
          }}
        >
          <Box sx={{backgroundColor: 'white', padding: {lg: '0 35px', xxl: '0 40px'}}}>{isCalendarOpen ? <CalendarWithMobileModal isDesktop={isDesktop} isOpen={isCalendarOpen} /> : null}</Box>
        </Grid>
        <Grid xxs={6} order={2} sx={{display: (!isWidget && isDesktop) || !arrival ? 'none' : 'block'}}>
          <FormInputTimeDepartureMobile />
        </Grid>
        <Grid xxs={6} order={4} sx={{display: (!isWidget && isDesktop) || !departure ? 'none' : 'block'}}>
          <FormInputTimeArrivalMobile />
        </Grid>
        <Grid
          xxs={12}
          order={6}
          sx={{
            display: isWidget || !isDesktop ? 'block' : 'none',
            padding: !selectedTimeField && 0,
          }}
        >
          {selectedTimeField ? (
            <TimeSlotsWithMobileModal isOpen={!!selectedTimeField} isDesktop={isDesktop}/>
          ) : null}
        </Grid>
      </Grid>
      {state.validationState === 'invalid' && <span style={{color: 'red'}}>Invalid</span>}
      <DefaultPopover />
    </I18nProvider>
  );
}
export const DateRangePicker = withErrorBoundary(DateRangePickerComp, 'DateRangePicker');
const CalendarWithMobileModal = withErrorBoundary(({isDesktop, isOpen}) => {
  if(isDesktop) {
    return <RangeCalendar />
  }
  return <Modal  open={isOpen} disablePortal={false} disableScrollLock={false}><Paper onMouseDown={e => e.stopPropagation()} className={clsx(styles.mobileModalPaper, 'no-outsideclick')}><RangeCalendar /></Paper></Modal>
},"CalendarWithMobileModal")

const TimeSlotsWithMobileModal = withErrorBoundary(({isDesktop, isOpen}) => {
  if(isDesktop) {
    return <ScrollBox scrollLock  className={styles.timeWrapperMobile}>
    <TimeSlotCategories wrapperClassName={styles.timeWrapperMobile} />
  </ScrollBox>
  }
  return <Modal  open={isOpen} disablePortal={false} disableScrollLock={false}><Paper onMouseDown={e => e.stopPropagation()} className={clsx(styles.mobileModalPaper, 'no-outsideclick', styles.timeSlotsModalMobile)}><ScrollBox scrollLock className={styles.timeWrapperMobile}>
  <TimeSlotCategories wrapperClassName={styles.timeWrapperMobile} />
</ScrollBox></Paper></Modal>
},"TimeSlotsWithMobileModal")