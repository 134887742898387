import {palette} from '../scss';

declare module '@mui/material/Accordion' {
  interface AccordionPropsVariantOverrides {
    base: true;
    mobile: true;
  }
}

const muiAccordionTheme = _theme => {
  return {
    components: {
      /**
       * MuiAccordion
       */
      MuiAccordion: {
        defaultProps: {
          disableGutters: true,
        },
        variants: [
          {
            props: {variant: 'mobile'},
            style: {
              boxShadow: 'none',
              borderBottom: `1px solid ${palette.colorBlack}`,
              '&:last-of-type': {
                borderBottom: 0,
              },
              '&::before': {
                height: '0',
              },
              '& .MuiAccordionDetails-root': {
                padding: 0,
              },
              '& .MuiAccordionSummary-root': {
                position: 'relative',
                padding: 0,
              },
              '& .MuiAccordionSummary-content': {
                padding: '15px 0',
                margin: 0,
              },
            },
          },
          {
            props: {variant: 'base'},
            style: {
              boxShadow: 'none',
              '&::before': {
                height: '0',
              },
              '& .MuiAccordionDetails-root': {
                padding: '0 15px 20px 15px',
              },
              '& .MuiAccordionSummary-root': {
                position: 'relative',
                padding: '0 15px',
              },
              '& .MuiAccordionSummary-content': {
                padding: '20px 0',
                margin: 0,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            // boxShadow: 'none',
            // '&::before': {
            //   height: '0',
            // },
          },
        },
      },
      /**
       * MuiAccordionDetails
       */
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            // backgroundColor: palette.colorPrimary,
          },
        },
      },
      /**
       * MuiAccordionSummary
       */
      MuiAccordionSummary: {
        styleOverrides: {
          root: () => ({
            // backgroundColor: palette.colorPrimary,
            // position: 'relative',
            // padding: 0,
            // height: '60px',
            // '& .MuiAccordionSummary-content': {
            //   margin: '15px 0',
            // },
            // '& .MuiAccordionSummary-expandIconWrapper': {
            //   color: palette.colorBlack,
            // },
            // '& .MuiAccordionSummary-prefixIcon': {
            //   position: 'absolute',
            //   left: '20px',
            //   top: '11px',
            // },
          }),
        },
      },
    },
  };
};

export default muiAccordionTheme;
