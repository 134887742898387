import clsx from 'clsx';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {useEffect, useState} from 'react';
import {palette} from 'styles/mui/scss';

import {useStarcarDateRangePickerStateContext} from '../../../hooks/BPR/useStarcarDateRangePickerState';
import Box from '../Box';
import {Button} from '../Button';
import {CustomIcon} from '../CustomIcon/CustomIcon';
import Grid from '../Grid';
import Typography from '../Typography';
import styles from './styles.module.scss';
import {getDateInBerlinTime} from "utils/date/formatDate";

function scrollToTimeslot(containerClassName, timeslotId, isMobile) {
  // Find the scrollable container
  const container = document.querySelector(`.${containerClassName}`) as HTMLElement;
  if (container) {
    // Find the button with the specific timeslot ID within the container
    const button = container.querySelector(
      `button[data-timeslot-id="${timeslotId}"]`,
    ) as HTMLElement;
    if (button) {
      // Scroll the container to the button
      if (isMobile) {
        container.scrollTop = button.offsetTop - button.clientHeight * 2 - 10;
      } else {
        container.scrollTop = button.offsetTop - button.clientHeight * 2 - 59;
      }
    }
  }
}
export const TimeSlotCategories = ({wrapperClassName}) => {
  const [t] = useTranslation('bpr');
  const {
    handleTime,
    selectedTimeField,
    timeValue,
    availableTimeSlots,
    timeOptions,
    formValues,
    isWidget,
    setSelectedTimeField,
    isDesktop,
    closeModal,
    datepickerData,
  } = useStarcarDateRangePickerStateContext();
  const isDeparture = selectedTimeField === 'departureTime';
  const categorizedTimeSlots = isDeparture ? availableTimeSlots : timeOptions;
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  useEffect(() => {
    if (selectedTimeField) {
      scrollToTimeslot(wrapperClassName, timeValue, !isDesktop);
      setSelectedTimeSlot(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeField]);
  return (
    <>

      <Box
        sx={{
          display: 'flex',
          // alignItems: 'center',
          padding: '14px 0 10px',
          position: 'sticky',
          flexDirection: 'column',
          top: '0',
          zIndex: 1,
          backgroundColor: palette.colorWhite,
          
        }}
        className={clsx(styles.timeSlotModalHeader, {[styles.isWidget]: isWidget})}
      >
        <Typography className={styles.timeSlotInfoText} >
            <b>
              {selectedTimeField === 'departureTime'
                ? t('chooseDepartureTimeDeparture')
                : t('chooseDepartureTimeArrival')}
            </b>
          </Typography>
          {!(isDesktop && !isWidget) ? (
            <CustomIcon
              name="close"
              onClick={() => setSelectedTimeField(null)}
              className={styles.timeSelectMobileIcon}
            />
          ) : null}
        <Grid  className={styles.timeSlotInfoText}container >
          <Grid display="flex" alignItems="start" flexDirection="row" xs={12}>
          </Grid>

          <Grid xs={12} sx={{ marginTop: '10px'}} display="flex">
            <CustomIcon
              style={{height: '24px', width: '24px', marginRight: '10px', flexShrink: 0}}
              name="clockAlt"
            ></CustomIcon>
            <Typography sx={{lineHeight: '24px',}} >{selectedTimeField === 'departureTime'? `${t('businessHours', {ns: 'common'})}: ${datepickerData.allowedTimes[getDateInBerlinTime(formValues?.departure).getDay()]?.start} - ${datepickerData.allowedTimes[getDateInBerlinTime(formValues?.departure).getDay()]?.end}` : t('return24h')}</Typography>
          </Grid>
        </Grid>
        <span className={styles.headerDivider}></span>
      </Box>
      <Box
        sx={{
          margin: '0 auto',
          maxWidth: '530px',
        }}
      >
        {categorizedTimeSlots.map((category) => {
          if (category.timeSlots.length > 0) {
            return (
              <div key={category.name}>
                <Typography
                  sx={{
                    lineHeight: '24px',
                    color: palette.colorGrey80,
                    paddingBottom: '15px',
                    // position: {xxs:'sticky'},
                    top: {xxs: 102},
                    marginTop: '-2px',
                    marginBottom: '2px',
                    zIndex: 0,
                    width: '100%',
                    backgroundColor: 'white',
                  }}
                >
                  {t(category.name)}
                </Typography>
                <Grid
                  container
                  columnSpacing={5}
                  rowSpacing={2.5}
                  sx={{
                    paddingLeft: '25px',
                    paddingRight: '25px',
                    paddingBottom: '40px',
                  }}
                >
                  {category.timeSlots.reduce((acc, timeSlot, index) => {
                    if(index === 0) {
                      const pushSlots = Math.floor(parseInt(timeSlot.split(':')[1],10) / 15);
                      for(let i = 0; i < pushSlots; i++) {
                        acc.push(null)
                      }
                    }
                    acc.push(timeSlot);
                    return acc;
                  },[]).map((timeSlot, index) => {
                    if(!timeSlot) {
                      return <Grid key={`${timeSlot}-${index}`} xxs={6} xs={6} sm={3}></Grid>
                    }
                    return (
                      <Grid key={`${timeSlot}`} xxs={6} xs={6} sm={3}>
                        <button
                          className={styles.timeButton}
                          data-timeslot-id={timeSlot}
                          onClick={e => {
                            e.preventDefault();
                            if (isDesktop) {
                              handleTime(selectedTimeField, timeSlot);
                            } else {
                              setSelectedTimeSlot(timeSlot);
                            }
                          }}
                        >
                          <Typography
                            variant="buttonCards"
                            className={clsx(styles.buttonCard, {
                              [styles['timeSlot--active']]:
                                timeSlot === selectedTimeSlot && !isDesktop,
                              [styles['timeSlot--active-bg']]:
                                timeValue === timeSlot && (isDesktop || !selectedTimeSlot),
                            })}
                          >
                            {timeSlot}
                          </Typography>
                        </button>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            );
          }
        })}
      </Box>
      <Box
      className={styles.timeConfirmButton}
              sx={{width: '100%',display: {lg: 'none'}}}

      >
        <span className={styles.buttonDivider}></span>
        <Button
        // className={styles.timeConfirmButton}
        disabled={!selectedTimeSlot}
        onClick={() => {
          closeModal();
          handleTime(selectedTimeField, selectedTimeSlot);
        }}
        fullWidth
      >
        {t('confirmSelection')}
      </Button>
      </Box>
    </>
  );
};
