import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

export const useUpdateTranslations = () => {
  const router = useRouter();
  const [_locale, setLocale] = useState(router.locale);
  useEffect(() => {
    setLocale(router.locale);
  }, [router.locale]);
};
