import useCustomController from 'hooks/useCustomController';
import {ReactNode, useEffect} from 'react';
import {useLocale} from 'react-aria';
import {useFormContext} from 'react-hook-form';

import {DateValue} from '@internationalized/date';
import {StandardTextFieldProps} from '@mui/material';

import {transformDateFormat} from 'utils/date/formatDate';

import TextField from '../TextField';

interface FormInputDateProps extends Omit<StandardTextFieldProps, 'variant' | 'onChange'> {
  name: string;
  label: ReactNode;
  placeholder: string;
  variant?: 'standard' | 'filled';
  onChange?: (date: DateValue) => void;
}

export const FormInputDate = ({
  name,
  label,
  placeholder,
  variant,
  value,
  ...rest
}: FormInputDateProps) => {
  const {
    control,
  } = useFormContext();
  const {
    fieldState: {error},
    field: {value: fieldValue, onChange, onBlur, ref},
  } = useCustomController({control, name, defaultValue: ''});
  useEffect(() => {
    onChange(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const {locale} = useLocale();
  // console.log(rest);
  return (
    <TextField
      variant={variant}
      error={Boolean(error?.message)}
      helperText={error?.message}
      label={label}
      placeholder={placeholder}
      {...rest}
      value={transformDateFormat(fieldValue, locale)}
      onChange={() => {}}
      onBlur={onBlur}
      name={name}
      ref={ref}
    />
  );
};
